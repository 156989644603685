import { useRouter } from 'next/dist/client/router'

interface SafeHydrateProps {
  children: React.ReactElement
  fallback: React.ReactNode | null
}

const SafeHydrate = ({ children, fallback }: SafeHydrateProps) => {
  const { isReady: isQueryReady } = useRouter()
  return <div suppressHydrationWarning>{typeof document === 'undefined' || !isQueryReady ? fallback : children}</div>
}

export default SafeHydrate

SafeHydrate.defaultProps = {
  fallback: null,
}
