import FullscreenError from 'components/FullscreenError'
import SafeHydrate from 'components/SafeHydrate'

const Custom404 = () => {
  return (
    <SafeHydrate>
      <FullscreenError statusCode={404} />
    </SafeHydrate>
  )
}

export default Custom404
